import React, {useState} from 'react';
import Counter from './components/Counter';
import ClassCounter from './components/ClassCounter';

function App() {
  const [val, setVal] = useState('text')

  return (
    <div className="App">
      <Counter/>
      <ClassCounter/>
      

       <h1>{val}</h1>
       <input 
          type="text"
          value={val}
          onChange={e => setVal(e.target.value)}
        />
       
    </div>
  );
}

export default App;
